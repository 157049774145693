import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import "utilities/hyphenate"
import { openModal } from "flows/modal/actions"
import { ReactComponent as EditIcon } from "./edit.svg"
import UserFigure from "../UserFigure"
import { getUserType } from "../../utilities/user-type"

const Card = styled.div`
  background-color: #fff;
  border-bottom: 1px solid whitesmoke;
  position: relative;
  display: flex;
`

const UserAvatar = styled.div``

const UserDetails = styled.div`
  display: inline-block;
  vertical-align: top;
`

const EditUserButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border: 2px solid #c6c6c5;
  outline: none;
  appearance: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: top;
  transition: fill 0.13s, background 0.13s;
  cursor: pointer;
  background: transparent;
  &:hover {
    background: #c6c6c5;
    svg {
      fill: #fff;
    }
  }
  svg {
    fill: #c6c6c5;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`

const detailsColumnOne = user => [
    { label: "Title", content: user.title },
    { label: "Name", content: user.name },
    { label: "Given name", content: user.given_name },
    { label: "Family name", content: user.family_name },
    { label: "Email", content: user.email },
    { label: "Phone number", content: user.phone_number },
]

const detailsColumnTwo = user => [
    { label: "Rox iso language", content: user.iso_language },
    { label: "Rox iso country", content: user.iso_country },
    { label: "Rox region", content: user.region },
    { label: "Rox unit", content: user.unit },
    { label: "Company name", content: user.company_name },
    { label: "Customer number", content: user.customer_number },
]

const detailsColumnThree = user => {
    let details = []
    if (Object.prototype.hasOwnProperty.call(user, "address")) {
        details = details.concat([
            { label: "Postal code", content: user.address.postal_code },
            { label: "Region", content: user.address.region },
            { label: "Street address", content: user.address.street_address },
            { label: "Locality", content: user.address.locality },
        ])
    }
    return details
}

const Table = ({ data }) => (
    <div className="rt-top__table">
        {data.map((detail, index) => (
            <div className="rt-top__table-row" key={index}>
                <div className="rt-top__table-key">{detail.label}</div>
                <div
                    className="rt-top__table-value"
                    data-testid={`user-details-${detail.label.hyphenate()}`}
                >
                    {detail.content}
                </div>
            </div>
        ))}
    </div>
)

const UserCard = ({ capabilities, user, openModal }) => {
    const unsaved_changes = user.unsaved_changes

    const canEditUserInfo = Boolean(capabilities.canEditUserInfo)

    return (
        <Card className="user-card" data-testid="user-card">
            <UserAvatar className="user-card__avatar">
                <UserFigure inline imageURL={user.picture_url} />
            </UserAvatar>
            <UserDetails
                className="user-card__details rt-top"
                data-testid="user-details"
            >
                <h1 className="rt-top__title">{user.name} <span>({getUserType(user)})</span></h1>
                <div className="rt-top__content">
                    <Table data={detailsColumnOne(user)} />
                    <Table data={detailsColumnTwo(user)} />
                    <Table data={detailsColumnThree(user)} />
                </div>
            </UserDetails>
            {unsaved_changes && (
                <div data-testid="user-error">
                    <p>The user has unsaved changes.</p>
                </div>
            )}
            {canEditUserInfo && <div>
                <EditUserButton
                    className="icon-button"
                    onClick={openModal}
                    data-testid="edit-user-button"
                >
                    <EditIcon />
                </EditUserButton>
            </div>}
        </Card>
    )
}

const mapStateToProps = state => ({
    capabilities: state.capabilities,
})

const mapDispatchToProps = dispatch => ({
    openModal: () => dispatch(openModal()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCard)
