import React from "react"
import { connect } from "react-redux"
import { Link } from "@reach/router"

import UserFigure from "../UserFigure"

import { selectUser } from "flows/users/actions"
import { getUserType } from "../../utilities/user-type"

const CustomerBarDetails = ({ details = [], labels = [] }) => {
    const detailElements = details.map((detail, index) => (
        <div
            className="customer-bar__table-row"
            key={index}
            data-testid="customer-bar-detail"
        >
            <div className="customer-bar__table-key">{labels[detail.key]}</div>
            <div className="customer-bar__table-value">{detail.value}</div>
        </div>
    ))

    return <div className="customer-bar__table">{detailElements}</div>
}

const CustomerBar = ({ customer = {}, labels = [], selectCustomer }) => (
    <div className="customer-bar" data-testid="customer-bar">
        <UserFigure float imageURL={customer.image_url} />
        <h3 className="customer-bar__title">
            <Link
                to="/user"
                onClick={() => selectCustomer(customer.id)}
                data-testid="customer-bar-link"
            >
                {customer.name}
            </Link>
            <span>({getUserType(customer)})</span>
        </h3>
        <div className="customer-bar__meta">
            {customer.details && (
                <CustomerBarDetails details={customer.details} labels={labels} />
            )}
        </div>
    </div>
)

const mapDispatchToProps = dispatch => ({
    selectCustomer: customerID => dispatch(selectUser(customerID)),
})

export default connect(
    null,
    mapDispatchToProps
)(CustomerBar)
