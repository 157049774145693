import { createLogic } from "redux-logic"
import axios from "axios"

import {
    SEARCH_USERS,
    SEARCHING_USERS,
    SEARCH_ABORT,
    SEARCH_ABORTED,
    SEARCH_FAILED,
    SEARCH_DONE,
    SAVING_USER,
    SAVING_USER_ABORTED,
    SAVE_USER,
    USER_SAVED,
} from "./action_types"

import { CLOSE_MODAL } from "../modal/action_types"
import { ERROR_MESSAGE } from "../messages/action_types"

import {
    mapUserFromAPIToModel,
    mapUsersFromAPIToModel,
    mapUserFromModelToAPI,
    companyNameKey,
    customerNumberKey,
} from "./utilities"
import { dispatchError } from "utilities/dispatch-error"

const getServiceRoleNames = getState =>
    getState().roles_per_service.map(service => service.claim_name)

const search = async ({ getState }, dispatch, done) => {
    dispatch({
        type: SEARCHING_USERS,
    })

    const fields = [
        "email",
        "name",
        "user_id",
        "user_metadata",
        "app_metadata",
        "picture",
    ]

    const searchQuery = searchTerm => {
        // Search for Auth0 users as well as AzureAD users. The latter is important since
        // Roxtec users will login to the applications using their AD identity from now on. 
        let query = "(user_id:auth0|* OR user_id:waad|*)"

        if (searchTerm !== undefined) {
            // Wildcards does not work with metadata fields. We must use exact search there.
            query += ` AND (user_metadata.${companyNameKey}:"${searchTerm}" OR app_metadata.${customerNumberKey}:"${searchTerm}" OR user_metadata.given_name:"${searchTerm}" OR user_metadata.family_name:"${searchTerm}"`

            // Skip name search if search term includes @
            if (searchTerm.indexOf("@") === -1) {
                query += ` OR name:${searchTerm}*`
            }

            // Suffix matching only works with at least 3 characters.
            if (searchTerm.length >= 3) {
                // Only include email search if no whitespace included in search
                // If your search term starts with two letters followed by a space, you'll get a 400 Bad Request.
                if (searchTerm.indexOf(" ") === -1) {
                    query += ` OR email:*${searchTerm}*`
                }
            }

            query += ")"
        }

        const filterQueryString = filterQuery()

        if (filterQueryString === "") {
            return query
        }

        if (searchTerm !== undefined) {
            return query + `AND ${filterQueryString}`
        }

        return filterQueryString
    }

    const selectedFilterNames = (service, active_filters) => {
        return service.roles
            .filter(
                r =>
                    active_filters[service.claim_name] &&
          active_filters[service.claim_name].includes(r.id)
            )
            .map(r => r.id)
    }

    const filterQuery = () => {
        const state = getState()
        return state.roles_per_service
            .map(service => {
                const selectedFilters = selectedFilterNames(
                    service,
                    state.active_filters
                )

                if (!selectedFilters.length) {
                    return null
                }

                const selectedFiltersString = selectedFilters.join(" +")

                return `app_metadata.${service.claim_name}:(+${selectedFiltersString})`
            })
            .filter(Boolean)
            .join(" AND ")
    }

    const params = () => {
        const searchParams = new URLSearchParams()
        searchParams.append("q", searchQuery(getState().search.search_term))
        searchParams.append("search_engine", "v3")
        searchParams.append("include_totals", true)
        searchParams.append("include_fields", true)
        searchParams.append("fields", fields)
        return searchParams
    }

    try {
        const response = await axios({
            method: "get",
            url: "/api/v2/users",
            params: params(),
            headers: { authorization: `Bearer ${getState().auth.accessToken}` },
            validateStatus: status => status === 200,
        })
    
        dispatch({
            type: SEARCH_DONE,
            total: response.data.total,
            users: mapUsersFromAPIToModel(
                response.data.users,
                getServiceRoleNames(getState)
            ),
        })
    } catch (error) {
        dispatch({
            type: SEARCH_ABORTED,
            error,
        })

        dispatch({
            type: SEARCH_FAILED,
        })

        dispatchError(dispatch, error)
    }

    done()
}

const triggerSearchOnTextChange = createLogic({
    type: SEARCH_USERS,
    cancelType: SEARCH_ABORT,
    latest: true,
    debounce: 200,
    process: search,
})

const save = createLogic({
    type: SAVE_USER,
    latest: true,
    async process({ getState }, dispatch, done) {
        dispatch({
            type: SAVING_USER,
        })

        const user = getState().users.list.find(user => user.selected)

        // If the user has a patch object, use that. In this case, we only send changed
        // properties instead of the entire user object.
        const modelUser = typeof user.patch === "object" ? user.patch : user
        
        const APIuser = mapUserFromModelToAPI(modelUser, getServiceRoleNames(getState))
        delete APIuser.user_id // We do not want to update the user_id

        try {
            const response = await axios({
                method: "patch",
                url: `/api/v2/users/${user.id}`, // TODO: Read from config
                headers: { authorization: `Bearer ${getState().auth.accessToken}` },
                data: APIuser,
                validateStatus: status => status === 200,
            })

            const savedUser = mapUserFromAPIToModel(
                response.data,
                getServiceRoleNames(getState)
            )
            dispatch({
                type: USER_SAVED,
                user: savedUser,
            })
            dispatch({ type: CLOSE_MODAL })
        } catch (err) {
            dispatch({
                type: SAVING_USER_ABORTED,
            })

            dispatchError(dispatch, err)
        }

        done()
    },
})

export default [triggerSearchOnTextChange, save]
